/* Position the image container (needed to position the left and right arrows) */
.iris-carousel-wrapper {
  position: relative;
  width: 100%;
}

/* Hide the images by default */
.slide {
  display: none;
  width: 100%;
  height: auto;
  margin: 0 auto;
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  &.show {
    display: flex;
    justify-content: center;
  }
}

/* Add a pointer when hovering over the thumbnail images */
.cursor {
  cursor: pointer;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  background-color: #000;
  top: 40%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* Container for image text */
.caption-container {
  text-align: center;
  background-color: #222;
  padding: 2px 16px;
  color: white;
}

.row {
  overflow: auto;
  white-space: nowrap;
  // display: flex;
  & * {
    display: inline-block;
    width: 20%;
    padding: 0.125rem;

    img {
      width: 100%;
    }
  }
}

/* Add a transparency effect for thumnbail images */
.demo {
  opacity: 0.6;
}

.active,
.demo:hover {
  opacity: 1;
}
