// headers

.title-sm {
  font-size: 2rem;
}

.title-md {
  font-size: 2.25rem;
}
.title-lg {
  font-size: 2.5rem;
}
.title-xl {
  font-size: 3.25rem;
}

.color-red-primary {
  color: $color-red-primary;
}
