.list-wrapper {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  .sort-select {
    height: 80px;
  }
  .list-component {
    flex: 1;
    background-color: #e2e2e2;
    overflow: auto;

    .list-item {
      cursor: pointer;
      box-sizing: border-box;
      background-color: transparent;
      border: 3px solid transparent;
      &:hover {
        // background: rgba($color: #000000, $alpha: 0.2);
        border-color: #3384bd;
      }
      &.active {
        box-sizing: border-box;
        background-color: $color-blue-secondary;
        color: #fff;
        &:hover {
          background-color: #3384bd;
        }
      }
    }
  }
  .list-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 4%;
    z-index: 20;
    background: $color-blue-secondary;
    color: white;
    font-size: 1rem;

    .page-input {
      width: 3rem;
      font-size: 1rem;
      text-align: center;
    }
  }
}
