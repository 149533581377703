.dialog-content-wrapper {
  min-width: 600px;
  max-height: 80vh;
  padding: 2em;
  //   display: flex;
  overflow-y: scroll;

  color: grey;

  .title {
    font-size: 1.6rem;
    margin: 1rem 0;
    font-weight: 600;
  }
  .subtitle {
    font-size: 1.4rem;
    font-weight: 500;
  }

  .details-title {
    font-size: 1.2rem;
    margin-bottom: 0.375rem;
  }
  .details-content {
    font-size: 1rem;
  }

  .confirm-checkbox {
    margin-right: 0.375rem;
    &:checked {
      ~ .confirm-label {
        color: grey;
      }
    }
  }
  .confirm-label {
    color: red;
  }
}
