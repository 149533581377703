* {
  box-sizing: border-box;
}
.imgZoomContainer {
  position: relative;
  display: flex;
  width: 100%;
  // width: 40vw;
  &:focus {
    outline: 2px solid red;
  }
}

.imgZoomLen {
  position: absolute;
  border: 3px solid #ffffff;
  // border-radius: 50%;
  z-index: 100;
  cursor: crosshair;
  /*Set the size of the magnifier glass:*/
  width: 400px;
  height: 300px;
}

.imageContainer {
  // max-width: 50vw;
  width: 100%;
  cursor: crosshair;
}

.zoomDetailsPanel {
  position: absolute;
  top: 0;
  margin-left: 0.375rem;
  left: 100%;
  width: 100%;
  height: 100%;
}
