.dialog-content-wrapper {
  padding: 0.725em;
}

.dialog-title {
  font-size: $title-font-size-sm;
}

.dialog-message {
  font-size: $font-size-xs;
}
