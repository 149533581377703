.slidecontainer {
  margin-top: 00.375rem;
  padding: 0.375rem;
  width: 100%;
}

.slider {
  $thumbWidth: 15px;
  appearance: none;
  width: 100%;
  height: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    appearance: none;
    width: $thumbWidth;
    height: $thumbWidth;
    border-radius: 50%;
    background: #3384bd;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: $thumbWidth;
    height: $thumbWidth;
    background: #3384bd;
    cursor: pointer;
  }
}
