// colors
$color-red-primary: #d9534f;

$color-blue-primary: #0097bf;
$color-blue-secondary: #3384bd;

// title font size
// $title-font-size-xs: 1.8rem;
// $title-font-size-sm: 2.25rem;
// $title-font-size-default: 2.5rem;
// $title-font-size-md: 3rem;
// $title-font-size-lg: 3.5rem;
// $title-font-size-xl: 4rem;

$title-font-size-xs: 1.125rem;
$title-font-size-sm: 1.4rem;
$title-font-size-default: 1.5625rem;
$title-font-size-md: 1.875rem;
$title-font-size-lg: 2.1875rem;
$title-font-size-xl: 2.5rem;

// font size
$font-size: 1.125rem;
$font-size-xs: 1.25rem;
$font-size-sm: 1.4rem;
$font-size-md: 1.5625rem;
$font-size-lg: 2rem;

// margin vertical
$space-vertical-xs: 1rem;
$space-vertical-sm: 2rem;
$space-vertical-md: 4rem;
$space-vertical-lg: 6rem;
$space-vertical-xl: 8rem;

// margin horizontal
$space-horizontal-xs: 2rem;
$space-horizontal-sm: 5rem;
$space-horizontal-md: 10rem;
$space-horizontal-lg: 15rem;
$space-horizontal-xl: 20rem;

$bp-xl: 90em; // 1440px
$bp-lg: 67.5em; // 1080px
$bp-md: 60em; // 960px
$bp-sm: 45em; // 720px
$bp-xs: 31.25em; // 500px
