.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  z-index: 2000;
  display: none;
  opacity: 0;
  transition: 0.2s;
}

.popupPanel {
  position: fixed;
  max-width: 1400px;
  min-width: 500px;
  width: 75%;
  height: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #212121;
  overflow: auto;
  color: #ffffff;
  border: 1rem;
  padding: 2rem;
}
