.header-wrapper {
  position: relative;
  padding: 0 $space-horizontal-sm;
  background-color: $color-blue-secondary;
  color: #fff;
  height: 8vh;

  @include mediaLayout(tab-port) {
    padding: 0 $space-horizontal-xs;
  }
  @include mediaLayout(phone) {
    padding: 1rem;
  }
  .header {
    position: relative;
    border-bottom: 2px solid $color-blue-primary;
    display: flex;
    justify-content: center;
    @include clearFloat;
  }

  .logo-title-container {
    @include flexCenter;
    height: 100%;
    float: left;
    .image {
      position: relative;
      width: 10rem;
      height: 6rem;
      display: inline-block;

      @include mediaLayout(phone) {
        width: 6rem;
        height: 3.6rem;
      }
    }
    .header-title {
      display: inline-block;
      font-size: $title-font-size-sm;
      margin-left: $space-horizontal-xs;
      @include mediaLayout(tab-port) {
        font-size: $title-font-size-default;
      }
      // @include mediaLayout(phone) {
      //   // hide title when screen size is too small
      //   display: none;
      // }

      @media screen and (max-width: 650px) {
        display: none;
      }
    }
  }

  .link-and-user-icon {
    height: 100%;
    @include flexCenter;
    float: right;

    .menu-text {
      text-decoration: none;
      color: #fff;
      font-size: $font-size;
      // @media screen and (max-width: 760px) {
      //   font-size: 0.725rem;
      // }
      @include mediaLayout(phone) {
        font-size: $title-font-size-xs;
      }
    }

    .search-toggle {
      position: relative;
      display: block;
      height: 10px;
      width: 10px;
      margin: 0 1rem;
      padding: 0 0.624rem;
      cursor: pointer;
      line-height: 1;
      width: 3rem;
      height: 3rem;

      &::before,
      &::after {
        content: "";
        position: absolute;
        display: inline-block;
        transition: all 0.1s;
      }

      &::before {
        border: 2px solid #fff;
        border-radius: 50%;
        width: 2.2rem;
        height: 2.2rem;
        top: 0.3rem;
        left: 0.2rem;
      }

      &::after {
        height: 2px;
        width: 1.6rem;
        background: #fff;

        top: 2.2rem;
        left: 1.2rem;
        transform: rotate(45deg);
      }

      &.active {
        &::before {
          height: 2px;
          width: 2.5rem;
          background: #fff;
          border: none;
          border-radius: 0;

          top: 1.4rem;
          left: 0;
          transform: rotate(-45deg);
        }

        &::after {
          width: 2.5rem;
          top: 1.4rem;
          left: 0.1rem;
        }
      }
    }
  }

  .hidden-content {
    // overflow: hidden;
    background-color: #fff;
    width: 100%;
    position: absolute;
    left: 0;
    top: 100%;
    transform-origin: top;
    z-index: 10;
    transform: scaleY(0);
    transition: all 0.2s;
    &.show {
      transform: scaleY(1);
      // z-index: 10;
    }
  }

  .menu-link {
    &::after {
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 0;
      height: 4px;
      background-color: #fff;
      content: "";
      transition: all 0.3s;
    }
    &:hover {
      &::after {
        left: 10%;
        width: 80%;
      }
    }

    &.active {
      background-color: rgba(0, 0, 0, 0.2);
      &::after {
        left: 10%;
        width: 80%;
      }
    }
  }

  .user-profile-container {
    @include flexCenter;
    margin-left: calc($space-horizontal-xs / 4);

    .user-profile-name {
      margin-left: calc($space-horizontal-xs / 2);
      font-size: $font-size;
    }
  }
}
