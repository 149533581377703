.snackbar {
  display: none;
  min-width: 150px;
  // margin-left: -125px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1000;
  left: 50%;
  top: 30px;
  transform: translate(-50%, 0);
  font-size: 14px;
  // font-size: 14px;
}
.show {
  display: inline-block;
  // animation: fadein 0.5s, fadeout 0.5s 2.5s forwards;
  animation: fadein 0.3s forwards;
}

@keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 30px;
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    top: 30px;
    opacity: 1;
  }
  to {
    top: 0;
    opacity: 0;
  }
}
