.upload {
  margin: 0 0 1rem 0rem;
  display: flex;
}

.uploadButton {
  margin: 0 1rem 0 0;
  min-width: 270px;
}

.uploadButton p {
  margin: 0.1rem 0.1rem 0 0;
}

.uploadChips {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
}

.uploadPerChip {
  margin: 0 0.2rem 0.3rem 0;
}