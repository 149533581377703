.backdrop {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: #00000077;
  top: 0;
  left: 0;
  z-index: 101;
}

.modal {
  width: 85vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  /* default is transparent, better css when basemap is chagned, otherwise, the image underneath it will be exposed */
  background-color: lightgray;
}

.map {
  width: 100%;
  height: 85vh;
}
.modalInfo {
  background-color: #fff;
  color: #000;
  font-size: 1rem;
  text-align: center;
  padding: 0.75em 0.5em;
}

.buttonGroup {
  background-color: #fff;
  padding: 1rem 0;
  display: flex;
  justify-content: flex-end;
  gap: 0.375rem;
}

.mapToolTip {
  color: #fff;
  background-color: #00000077;
}

.layerFilterDiv {
  width: 20rem;
  background-color: #fff;
  padding: 1em;
}

.dateRangePicker {
  background-color: #fff;
  padding: 0.5em 1em;
  display: flex;
  align-items: center;
  gap: 0.375em;
}

@media (max-width: 800px) {
  .modal {
    width: 600px;
  }
}

@media (max-width: 600px) {
  .modal {
    width: 450px;
  }
}
@media (max-width: 500px) {
  .modal {
    width: 350px;
  }
}
@media (max-width: 400px) {
  .modal {
    width: 300px;
  }
}
